import { toTitleCase } from '@/helpers/formatString';
import {
  ECopyStakeFeatureFlag,
  ELoyaltyFeatureFlag
} from '@/store/modules/feature-flags/FeatureFlagNames';

const featureNameMap = {
  [ELoyaltyFeatureFlag.COPY_STAKE]: 'CopyStake',
  [ECopyStakeFeatureFlag.GAMIFICATION_CENTER_SIGN_UP_REWARD]: 'Sign-Up Reward',
  [ECopyStakeFeatureFlag.GAMIFICATION_CENTER_WHEEL_OF_FORTUNE]:
    'Wheel of Fortune',
  [ECopyStakeFeatureFlag.GAMIFICATION_CENTER_JACKPOT]: 'Jackpot'
};

export const formatFeatureName = (key: string): string => {
  if (!key) return '';

  return featureNameMap[key] || toTitleCase(key.replace(/_/g, ' '));
};
