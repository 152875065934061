import { render, staticRenderFns } from "./SuperAdminConfigurationView.vue?vue&type=template&id=9c0faa90&scoped=true&lang=pug"
import script from "./SuperAdminConfigurationView.vue?vue&type=script&lang=ts"
export * from "./SuperAdminConfigurationView.vue?vue&type=script&lang=ts"
import style0 from "./SuperAdminConfigurationView.vue?vue&type=style&index=0&id=9c0faa90&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c0faa90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VContainer,VRow,VTab,VTabItem,VTabsItems,VTextField})
