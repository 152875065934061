






































import VTabsHash from '@/components/VTabsHash.vue';
import SuperAdminConfigurationCopyStake from '@/views/SuperAdminViews/super-admin-configuration/SuperAdminConfigurationCopyStake.vue';
import type { TTabsItem } from '@/api/schema';

const ETabKey: { [key: string]: string } = {
  COPY_STAKE: 'COPY_STAKE'
};

export default {
  name: 'SuperAdminConfigurationView',
  components: {
    VTabsHash,
    SuperAdminConfigurationCopyStake
  },
  data: (): any => ({
    currentTab: 0,
    tabs: [
      {
        id: ETabKey.COPY_STAKE,
        text: 'CopyStake',
        component: 'SuperAdminConfigurationCopyStake'
      }
    ] as TTabsItem[],
    searchPhrase: ''
  })
};
