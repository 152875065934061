























import { PropType } from 'vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { IGeneralFeatureFlagItem } from '@/api/schema';
import { updateGeneralFeatureFlagItem } from '@/api/CopyStake/FeatureFlags';
import { AxiosError } from 'axios';
import { formatFeatureName } from '@/helpers/featureFlagHelpers';

const ConfirmationDialog = (): Promise<any> =>
  import('@/components/ConfirmationDialog.vue');

export default {
  name: 'SuperAdminConfigurationItem',
  components: {
    ConfirmationDialog
  },
  filters: {
    formatFeatureName
  },
  props: {
    feature: {
      type: Object as PropType<IGeneralFeatureFlagItem>,
      default: {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      confirmationDialogState: false,
      confirmationDialogData: null,
      isProcessingEnableSwitch: false,
      isLoading: false
    };
  },
  computed: {
    formattedFeatureName(): string {
      return this.$options.filters.formatFeatureName(
        this.feature?.name || ''
      );
    }
  },
  methods: {
    changeEnableStatusHandler(): void {
      this.isProcessingEnableSwitch = true;
      const newStatus = !this.feature.enabled;

      this.confirmationDialogData = {
        header: 'Warning',
        disclaimer: `
          <span>
            Are you sure you want to ${
              newStatus ? 'activate' : 'deactivate'
            } <strong>${this.formattedFeatureName}</strong>?
          </span>`,
        okText: newStatus ? 'Activate' : 'Deactivate',
        handler: () => this.updateFeature(newStatus)
      };

      this.confirmationDialogState = true;
    },
    async updateFeature(status: boolean): Promise<void> {
      this.isLoading = true;
      this.confirmationDialogState = false;

      updateGeneralFeatureFlagItem(this.feature.id, status)
        .then(() => {
          this.feature.enabled = status;

          this.$toast.success(
            `"${this.formattedFeatureName}" ${
              status ? 'has been turned on' : 'has been turned off'
            }`
          );
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
          this.$emit('error');
        })
        .finally(() => {
          this.isLoading = false;
          this.closeConfirmationDialogHandler();
        });
    },
    closeConfirmationDialogHandler(): void {
      this.confirmationDialogState = false;

      this.isProcessingEnableSwitch = false;
      this.confirmationDialogData = null;
    }
  }
};
