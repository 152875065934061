





























import SuperAdminConfigurationItem from '@/views/SuperAdminViews/super-admin-configuration/SuperAdminConfigurationItem.vue';
import {
  IGeneralFeatureFlagItem,
  IGeneralFeatureFlagsListResponse
} from '@/api/schema';
import { getGeneralFeatureFlagItems } from '@/api/CopyStake/FeatureFlags';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { formatFeatureName } from '@/helpers/featureFlagHelpers';

export default {
  name: 'SuperAdminConfigurationCopyStake',
  components: {
    SuperAdminConfigurationItem
  },
  filters: {
    formatFeatureName
  },
  props: {
    searchPhrase: {
      type: String,
      default: ''
    }
  },
  data: (): any => ({
    featureFlagItems: [] as IGeneralFeatureFlagItem[],
    isLoading: false
  }),
  computed: {
    filteredFeatureFlagItems(): IGeneralFeatureFlagItem[] {
      const phrase = (this.searchPhrase || '').trim().toLowerCase();

      return (
        this.featureFlagItems?.filter((feature: IGeneralFeatureFlagItem) =>
          this.$options.filters
            .formatFeatureName(feature?.name || '')
            .toLowerCase()
            .includes(phrase)
        ) || []
      );
    }
  },
  created(): void {
    this.getFeatureFlagsData();
  },
  methods: {
    async getFeatureFlagsData(): Promise<void> {
      this.isLoading = true;

      getGeneralFeatureFlagItems()
        .then((res: IGeneralFeatureFlagsListResponse): void => {
          this.featureFlagItems = res.generalFeatureFlags || [];
        })
        .catch((err: AxiosError) => {
          this.featureFlagItems = [];
          errorToastMessage(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
